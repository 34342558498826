@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css');

:root {
	/* Colors */
	--c-t: #333333; /* Dark text */
	--c-lt: #ffffff; /* Light text */

	--c-lb: #9ad0f5; /* Boswell Light Blue */
	--c-db: #384556; /* Boswell Dark Blue */
	--c-gray: #c8d5dd; /* Boswell Gray */
	--c-lg: #e9eef0; /* Boswell Light Gray */
	--c-w: #ffffff; /* Boswell White */
	--c-b: #333333; /* Boswell Black */
	--c-dd-b: #28508c; /* Deluxe Draw Blue */
	--c-dd-b-o: rgba(40, 80, 140, 0.255); /* Deluxe Draw Blue with Opacity */
	--c-value-b: #deecfbd1; /* Value Purple*/
	--c-value-p: #e2dff1d2; /* Value Blue*/
	--c-value-g: #e3f1e4df; /* Value Green*/

	--c-db-o: #3845569d; /* Boswell Dark Blue with Hight Opacity */
	--c-db-o-low: #38455640; /* Boswell Dark Blue with Low Opacity */
	--c-lb-o: #9ad1f580; /* Boswell Light Blue with Opacity */

	--c-g: #23a823; /* Boswell Green */
	--c-g-o: #23a82375; /* Boswell Green with Opacity */

	--c-r: rgb(255, 90, 90); /* Boswell Red */
	--c-lr: rgb(255, 135, 135); /* Boswell Light Red */

	/* Padding and gaps */
	--p-xs: 0.25rem; /* Extra small*/
	--p-s: 0.5rem; /* Small */
	--p-m: 1rem; /* Medium */
	--p-l: 1.5rem; /* Large */
	--p-xl: 2rem; /* Extra Large */

	/* Text */
	--t-xs: 0.6rem; /* Extra small */
	--t-s: 0.75rem; /* Small */
	--t-m: 1rem; /* Medium */
	--t-l: 1.25rem; /* Large */
	--t-xl: 1.5rem; /* Extra Large */
	--t-lh: 1.5rem; /* Lineheight */
	--t-lh-xs: 1rem; /* Lineheight Extra small*/

	/* Shadow */
	--s-d: 0rem 0rem 0.25rem var(--c-b); /* Default */
	--s-s: 0rem 0.05rem 0.25rem var(--c-b); /* Small shadow */

	--fw: 500; /* Font weight */

	--border: 0.05rem solid;
	--br-s: 0.25rem; /* Border Radius Small */
	--br-m: 0.5rem; /* Border Radius Medium */
	--br-l: 1rem; /* Border Radius Large */
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family:
		system-ui,
		-apple-system,
		'Segoe UI',
		Roboto,
		'Helvetica Neue',
		Arial,
		'Noto Sans',
		'Liberation Sans',
		sans-serif;
	color: var(--c-text);
	line-height: var(--t-lh);
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.gap-small {
	gap: var(--p-s);
}

.gap-large {
	gap: var(--p-l);
}

.page-wrapper {
	width: 100dvw;
	height: 100dvh;
	background-color: var(--c-w);
}

.width-full {
	width: 100%;
}

.height-full {
	height: 100%;
}

.header-wrapper {
	z-index: 2;
}

.content-wrapper {
	height: 100%;
	overflow: hidden;
	display: flex;
}

a,
a:visited {
	color: inherit;
	text-decoration: none;
}

::-webkit-scrollbar {
	width: 0.45rem;
}

::-webkit-scrollbar-track {
	background: var(--c-lb);
}

::-webkit-scrollbar-thumb {
	background: var(--c-db);
}

::-webkit-scrollbar-thumb:hover {
	cursor: grabbing;
}

.error-msg {
	color: red;
}

.default-btn {
	background: none;
	border: none;
	color: inherit;
	font: inherit;
	cursor: pointer;
	padding: 0;
	margin: 0;
	outline: none;
	display: inline-block;
}

.default-btn i {
	font-size: var(--t-xl);
}

.default-btn:active {
	scale: 0.95;
}

.keep-scale:active {
	scale: 1;
}

.ellipsis {
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.ellipsis-long-word {
	overflow-wrap: break-word;
	word-break: break-all;
}

.margin-top {
	margin-top: var(--p-l);
}

.margin-bottom {
	margin-bottom: var(--p-s);
}

.shadow {
	box-shadow: 0px 2px 4px var(--c-db);
}

.user-select {
	user-select: none;
}

.remove-list-style {
	list-style: none;
}

.font-smaller {
	font-size: var(--t-s);
}

.font-bigger {
	font-size: 1.2em;
	font-weight: 500;
}

.align-self-end {
	align-self: end;
}

.padding-sides {
	padding: 0 var(--p-s);
}

.light-blue-hover:hover {
	background-color: var(--c-lb-o);
	border-radius: var(--br-s);
}

.border-radius {
	border-radius: var(--br-s);
}

.capitalize {
	text-transform: capitalize;
}

.tooltip {
	position: absolute;
	width: auto;
	padding: var(--p-xs) var(--p-s);
	background-color: var(--c-db);
	color: var(--c-w);
	border-radius: var(--br-m);
	text-align: center;
	white-space: nowrap;
	line-height: var(--t-lh-xs);
}

.font-weight-medium {
	font-weight: var(--fw);
}
.position-relative {
	position: relative;
}

.notes-img-preview {
	width: 3rem;
	margin: 0 auto;
}

.profile-container {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	font-size: var(--t-m);
	position: relative;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--c-db);
	color: var(--c-w);
	box-sizing: border-box;
}

.highlight-text {
	color: var(--c-lb);
}

.padding-small-sides {
	padding: 0 var(--p-xs);
}

.display-none {
	display: none;
}

.ql-snow .markdown-content-container pre {
	background-color: var(--c-b);
	color: var(--c-w);
	padding: var(--p-xs);
	white-space: normal;
	word-break: break-word;
	border-radius: var(--br-s);
}
