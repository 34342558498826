.dd-color-red {
	color: #f00;
}

.dd-color-green {
	color: #0f0;
}

.dd-color-blue {
	color: #00f;
}

.dd-marker-area {
	fill: var(--c-dd-b-o);
	cursor: grab;
}

.dd-marker-area:active {
	cursor: grabbing;
}

.dd-node-active {
	stroke: var(--c-dd-b);
	filter: drop-shadow(0 0 5px var(--c-dd-b));
}

.dd-node-edit {
	cursor: pointer;
}

.dd-node-edit:active {
	cursor: grabbing;
}

#dd-focusbox {
	position: absolute;
	display: block;
	left: 12rem;
	top: 1.5rem;
	z-index: 5;
	max-width: 10rem;
	margin: 0;
	text-align: start;
	background-color: var(--c-lb-o);
	box-shadow: var(--s-d);
	overflow-y: scroll;
}

.dd-focusboxItem:hover {
	background-color: var(--c-lb);
}

.dd-focusboxItem {
	margin: 0;
	padding: var(--p-xs) var(--p-s);
	font-size: var(--t-s);
	cursor: pointer;
}

.dd-zoombox {
	width: 352px;
	height: 352px;
	min-width: 100px;
	min-height: 100px;
	background-color: white;
	z-index: 2;
	position: absolute;
	bottom: 0;
	left: 0;
	border: 1px solid var(--c-lb);
	border-radius: 0px 5px 0px 0px;
}

.dd-cloneContainer {
	width: inherit;
	height: inherit;
	overflow: hidden;
}

.zoomboxResizeElement {
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	position: absolute;
	top: -15px;
	right: -15px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	color: var(--c-lb-o);
	cursor: ne-resize;
}

.zoomboxChangeZoomContainer {
	height: auto;
	width: auto;
	position: absolute;
	bottom: 0.8rem;
	right: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;
}

.changeZoomButton {
	background-color: var(--c-lb-o);
	cursor: pointer;
	width: 1.5rem;
	height: 1.5rem;
	border: none;
	border-radius: 50%;
	font-size: 18px;
	text-align: center;
}

.dd-nodeToolbar {
	width: auto;
	min-width: 20rem;
	height: 3.8rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: var(--c-lb-o);
	border-radius: 5px;
	position: absolute;
	top: 0.5rem;
	left: 5.5rem;
	z-index: 5;
}

.dd-nodeToolbarItem {
	margin: 0;
	padding: 5px;
	cursor: pointer;
}

#editNodeSelectShapeElement,
#editNodeSelectColorElement {
	width: auto;
	border: none;
	border-radius: 5px;
	padding: 4px;
}

#editNodeInputElement {
	width: 6rem;
	padding: 5px;
	border: none;
	border-radius: 4px;
}

@media (max-width: 1440px) {
	#dd-focusbox {
		left: 8.5rem;
	}

	.dd-focusboxItem {
		padding: 0 var(--p-xs);
	}
}
